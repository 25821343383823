import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import GoogleLogin from 'react-google-login';
import reportWebVitals from './reportWebVitals';

class App extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    
  }
  render() {
    return (
      <div className = 'app'>
        <Header></Header>
        <SignUpApp
        >
        </SignUpApp>
      </div>
    );
  }
}
class Header extends React.Component {
  render() {
    return (
      <header>
            <div className = 'skew'>
            </div>
            <div className = 'title'>GHL Manager</div>
      </header>
    )
  }
}
class SignUpApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      prospect : {
        firstName : null,
        lastName : null,
        email : null,
        password : null,
        passwordConfirm : null
      },
      stage : 0,
      stageError : Array(5).fill(null)
    }
  }
  signUpUser(config_json) {
    config_json.group = 'BE5G9ICBIH5KQWQ8';
    fetch('https://api.ghlmanager.com/user', {
      'method' : 'post',
      'headers' : {
        'content-type' : 'application/json'
      },
      'body' : JSON.stringify(config_json)
    }).then((result) => {
      return result.json();
    }).then((result) => {
      console.log(result);
    }).catch((error) => {
      console.error(error);
    })
  }
  renderInput(type, name, placeholder) {
    let refFunc;
    if (name === 'password-confirm') {
      refFunc = (element) => {

      }
    } else {
      refFunc = (element) => {
        element.focus();
      }
    }
    return (
      <Input 
        refFunc = {refFunc}
        type = {type}
        name = {name}
        placeholder = {placeholder}
        onChange = {(event) => {
          this.onInputChange(event);
        }}
        onKeyDown = {(event) => {
          if (event.keyCode === 13) {
            this.onButtonClick();
          }
        }}
      ></Input>
    );
  }
  renderStage(stage) {
    const stageMap = new Map();
    let className = 'stage'
    if (this.state.stageError[stage]) {
      className += ' error'
    }
    stageMap.set(0, () => {
      return this.renderInput('text', 'first-name', 'First Name');
    });
    stageMap.set(1, () => {
      return this.renderInput('text', 'last-name', 'Last Name');
    });
    stageMap.set(2, () => {
      return this.renderInput('text', 'username', 'Username');
    });
    stageMap.set(3, () => {
      return this.renderInput('text', 'email', 'Email');
    });
    stageMap.set(4, () => {
      return (
        <div className = 'password-form input'>
          {this.renderInput('password', 'password', 'Password')}
          {this.renderInput('password', 'password-confirm', 'Confirm Password')}
        </div>
      );
    });
    stageMap.set(5, () => {
      return (
        <div className = 'thank-you'>
          Thank You For Registering! 
          <br />
          We will be in contact with you shorty
        </div>
      )
    });
    const stageComponent = stageMap.get(stage)();
    let button;
    let title;
    if (stage < 5) {
      title = (
        <div className = 'title'>
          Sign Up For a New Account Today!
        </div>
      );
      button = (
        <Button
            className = 'next'
            onClick = {() => {
              this.onButtonClick();
            }}
            value = 'Next'
          >
          </Button>
      );
    }
    return (
      <div className = {className} key = {stage}>
        {title}
        {stageComponent}
        {button}
        <div className = 'error-message'>
          {this.state.stageError[stage]}
        </div>
      </div>
    );
  }
  onInputChange(event) {
    const target = event.target;
    const prospect = Object.assign({}, this.state.prospect);
    const nameMap = new Map();
    nameMap.set('first-name', () => {
      prospect.firstName = target.value;
    });
    nameMap.set('last-name', () => {
      prospect.lastName = target.value;
    });
    nameMap.set('username', () => {
      prospect.username = target.value;
    });
    nameMap.set('email', () => {
      prospect.email = target.value;
    });
    nameMap.set('password', () => {
      prospect.password = target.value;
    });
    nameMap.set('password-confirm', () => {
      prospect.passwordConfirm = target.value;
    });
    nameMap.get(target.name)();
    this.setState({
      prospect : prospect,
      stage : this.state.stage,
      stageError : this.state.stageError
    });
  }
  onButtonClick() {
    const prospect = Object.assign({}, this.state.prospect);
    let stage = this.state.stage;
    const stageError = this.state.stageError.map(x => x);
    const stageMap = new Map();
    stageMap.set(0, prospect.firstName);
    stageMap.set(1, prospect.lastName);
    stageMap.set(2, prospect.username);
    stageMap.set(3, prospect.email);
    stageMap.set(4, {
      password : prospect.password,
      passwordConfirm : prospect.passwordConfirm
    });
    if (stage < 4) {
      if (stageMap.get(stage) === null || stageMap.get(stage) === '') {
        stageError[stage] = 'Field is empty'
      } else {
        stageError[stage] = null
        stage++;
      }
      this.setState({
        prospect : prospect,
        stage : stage,
        stageError : stageError
      });
    } else if (stage === 4) {
      const password = stageMap.get(stage).password;
      const passwordConfirm = stageMap.get(stage).passwordConfirm;
      if (password === null) {
        stageError[stage] = 'Password is empty';
      } else if (password != null && password!=passwordConfirm) {
        stageError[stage] = 'Passwords do not match';;
      } else if (password != null && password == passwordConfirm) {
        stageError[stage] = null
        this.signUpUser({
          'first_name' : prospect.firstName,
          'last_name' : prospect.lastName,
          'username' : prospect.username,
          'email' : prospect.email,
          'password' : prospect.password,
        });
        stage++;
      }
      this.setState({
        prospect : prospect,
        stage : stage,
        stageError : stageError
      });
    }
  }
  render() {
    const stage = this.renderStage(this.state.stage)
    return (
      <div className = 'sign-up-app'>
        {stage}
        <GoogleLogin
          clientId = '480764079423-jhaomnl9ghun5e6s8l4sfedtq5huire3.apps.googleusercontent.com'
          buttonText="Login"
          onSuccess={(response) => {
            const email = response.profileObj.email;
            this.signUpUser({
              'first_name' : response.profileObj.givenName,
              'last_name' : response.profileObj.familyName,
              'username' : email.substring(0, email.indexOf('@')) + '_gmail_com',
              'email' : email,
              'password' : response.profileObj.googleId,
            });
          }}
          onFailure={(event) => {
              console.log(event);
          }}
          cookiePolicy={'single_host_origin'}
      />
      </div>
    );
  }
}
class Input extends React.Component {
  constructor(props) {
    super(props)
    this.input = React.createRef();
  }
  componentDidMount() {
    if (this.props.refFunc) {
      this.props.refFunc(this.input.current);
    }
  }
  render() {
    return (
      <div className = 'input'>
        <input
        ref = {this.input}
        type = {this.props.type}
        name = {this.props.name}
        onChange = {this.props.onChange}
        onKeyDown = {(event) => {
          this.props.onKeyDown(event);
        }}
        placeholder = {this.props.placeholder}
        >
        </input>
      </div>
    )
  }
}
class Button extends React.Component {
  render() {
    const className = 'button ' + this.props.className;
    return (
      <div className = {className}>
        <button
          name = {this.props.name}
          onClick = {this.props.onClick}
        >
          {this.props.value}
        </button>
      </div>
    )
  }
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
